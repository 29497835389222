/**
 * Login Page - Website code
 */

import React from "react";

// Imports the Amplify library from 'aws-amplify' package.
import { Amplify } from "aws-amplify";

import { Authenticator, withAuthenticator } from "@aws-amplify/ui-react";

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import "@aws-amplify/ui-react/styles.css";

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import awsExports from "../../aws-exports";

import "bootstrap/dist/css/bootstrap.min.css";
import PurchaseTP from "./PurchaseTP";
// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure(awsExports);

const Account = () => {
  {
    /* All wrapper classes for styling are called first inside the return.  */
  }
  return (
    <div className="account-wrapper">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="about-wrapper">
        <div className="about-us-container">
          <div className="rowC">
            <div className="form">
              <div className="Auth-wrapper">
                {/* Authenticator component  imported from aws  */}

                <Authenticator>
                  {({ signOut, user }) => (
                    <main>
                      <header className="account-header">
                        <div className="logged-in">
                          {/* Display user's email */}
                          <h1> Welcome {user?.signInDetails?.loginId}</h1>
                          <PurchaseTP />

                          {/* Sign Out Button */}
                          {/* Styling for the Sign out button */}

                          <button
                            className="about-button-2"
                            onClick={signOut}
                            style={{
                              margin: "20px",
                              fontSize: "0.8rem",
                              padding: "5px 10px",
                              marginTop: "20px",
                            }}
                          >
                            Sign Out
                          </button>
                        </div>
                      </header>
                    </main>
                  )}
                </Authenticator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
